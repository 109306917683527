<template>
  <div class="chat-container" ref="chatcontainer">
    <div class="top_left_action">
      <van-icon v-if="sourceType == 0" :size="$route.query.preview ? 30 : 70" name="arrow-left" @click="goBack" />
      <div v-if="!$route.query.preview &&
        sourceType == 1 &&
        hasEvaluationList &&
        !showEvaluationView
      " class="my_evaluation_bg" @click="toMyEvaluationClick">
        <van-image :src="require('../assets/image/my_evaluation_icon.svg')"></van-image>
        <span>我的</span>
      </div>
    </div>
    <span class="intelligent_agent_language_tips">* 智能体发言由 AI 生成</span>
    <div v-if="!$route.query.preview && !showEvaluationView" class="chat_message_list_and_call_bg">
      <van-button v-if="!iscallingUmi" class="chat_message_list_btn" @click="showChatMessageListMethod">
        <img :src="require('../assets/image/chat_message_list_icon.svg')" class="list_img" />
        <span class="list_tips">对话记录</span>
      </van-button>
      <!-- <van-button class="continue_call_btn" @click="callUmiClick">
        <img :src="iscallingUmi ? callUmiIcon_going : callUmiIcon_normal" />
      </van-button> -->
    </div>
    <div  v-if="sdkToken &&
      initUnityCallBack &&
      !showEvaluationView &&
      !hideInteractive &&
      webrtcConnect" class="interactive_buttons">
    <button v-if="scaleIconText!='' && scaleValid" class="evaluation_edit_button" @click="showEvaluationViewClick">
      <div class="evaluation_edit_info">
        <img :src="require('../assets/image/evaluation_edit_icon.svg')" class="evaluation_edit_icon" />
        <span class="evaluation_edit_title">{{ scaleIconText }}</span>
      </div>
    </button>
    <button v-if="chatSceneIconText!=''"
     class="evaluation_edit_button" @click="showInteractiveViewClick(1)" >
      <div class="evaluation_edit_info">
        <img :src="require('../assets/image/interactive_scene.svg')" class="evaluation_edit_icon" />
        <span class="evaluation_edit_title">{{ chatSceneIconText }}</span>
      </div>
    </button>
    <button v-if="storyIconText!=''
    " class="evaluation_edit_button" @click="showInteractiveViewClick(2)" >
      <div class="evaluation_edit_info">
        <img :src="require('../assets/image/interactive_sequence.svg')" class="evaluation_edit_icon" />
        <span class="evaluation_edit_title">{{ storyIconText }}</span>
      </div>
    </button>
  </div>
    <SceneSwitchButton ref="interactiveViewRef" v-if="interactiveId>0||true" @closeInteractive="hideInteractiveViewClick"  @voicePlayText="voicePlayText" class="SceneSwitchButton" :chatMenuList="chatMenuList" :linkardId = "linkardId" :interactiveId="interactiveId" @sceneClick="setRegisterSetChatParam" @closeUmiSpeak ="closeUmiSpeak"></SceneSwitchButton> 
    <VanFloatingPanel v-show="!iscallingUmi && !showEvaluationView && (!showInteractiveGuide && sdkToken &&
      initUnityCallBack &&
      webrtcConnect)" v-model:height="anchorHeight" :anchors="chatAnchors" :content-draggable="false">
      <template v-slot:header>
        <div class="van-floating-panel_header_view" @click="vanFloatingPanelClick">
          <div class="van-floating-panel__header-bar"></div>
        </div>
      </template>
      <div class="gradient-box"></div>
      <div :class="[
        'content',
        anchorHeight == chatAnchors[1] ? 'content_height' : '',
      ]" ref="chatContentBg" id="chat_content_bg">
        <van-list :class="[
          'chat-list-content',
          $route.query.preview ? 'chat_list_content_pc' : '',
        ]" :finished="true">
          <van-cell v-for="(item, index) in list" :key="index">
            <UmiChat v-if="item === currentBotAnswer" :text="currentBotAnswer.text" :sourceType="sourceType"
              :isLoding="loading" :triggerType="currentBotAnswer.triggerType" :fromAudio="currentBotAnswer.fromAudio"
              @showVideoPaly="showVideoPaly" />
            <UmiChat v-else-if="item.fromBot" :text="item.text" :sourceType="sourceType" :triggerType="item.triggerType"
              :fromAudio="item.fromAudio" :mediaUrl="item.mediaUrl" :url="item.url" :scaleIdIdentify="scaleIdIdentify"
              :itemScaleIdentify="item.scaleIdIdentify" @dismissVideoTriggerPlayView="dismissVideoTriggerPlayView"
              @chatScale="chatScaleMethod" @showVideoPaly="showVideoPaly" />
            <OwnChat v-else-if="!item.fromBot && item === currentUserAsk" :fromAudio="item.fromAudio"
              :text="item.text" />
            <OwnChat v-else :text="item.text" :fromAudio="item.fromAudio" />
          </van-cell>
        </van-list>
      </div>
    </VanFloatingPanel>
    <div v-show="!iscallingUmi && !showEvaluationView" class="send-content">
      <van-field v-if="!speakButtonSelect" ref="inputfield" v-model="message" rows="1" type="textarea" autosize
        placeholder="请输入..." enterkeyhint="send" @focus="inputFocus()" @keydown="handleKeyboard" />
      <div v-if="speakButtonSelect" class="speak_center_btn_bg">
        <div v-if="showUmiAudioView && speakInfoSendState === 1" class="speak_move_tips">
          - 松手后发送，上移可取消 -
        </div>
        <button ref="speakCenterBtnRef" :class="[
          'speak_center_btn',
          speakInfoSendState === 3 || speakInfoSendState === 4
            ? 'speak_center_listen_disable'
            : 'speak_center_listen',
        ]" @touchstart.native="speakCenterBttonClick" @touchend.native="handleSpeakCenterButtonEndClick"
          @touchcancel.native="handleSpeakCenterButtonEndClick" @touchmove.native="updatePosition">
          <span v-if="speakInfoSendState === 0" :class="[
            'hold_down_speak_tips',
            reviewStep != -1 ||
              !sdkToken ||
              !hasInitService ||
              !initUnityCallBack ||
              !webrtcConnect || micPermissionStatus == 2
              ? 'hold_down_speak_tips_disable'
              : '',
          ]">按住说话</span>
          <UmiAudioListenLoadingView v-if="speakInfoSendState === 2">
          </UmiAudioListenLoadingView>
        </button>
        <UmiAudioInfoView v-if="showUmiAudioView" :speakInfoSendState="speakInfoSendState"
          @stopCountDownMethod="stopCountDownMethod" @closeUmiSpeak="closeUmiSpeak" :umiName="npcName"
          class="umi_audio_info">
        </UmiAudioInfoView>
      </div>
      <ChatLottie v-if="message.length > 0" :disabled="answering" @click="() => toAnswer(message)" :autoPlay="true"
        ref="sendChat" class="talk-btn" title="发送" />
      <van-button v-else-if="speakInfoSendState == 0" :disabled="speakInfoSendState != 0 || answering"
        :class="['speak-mic-btn', speakButtonSelect ? 'speak-btn' : 'mic-btn']" @click="speakButtonClick">
        <img :src="speakButtonSelect
          ? require('../assets/image/chat_speak_keybord.svg')
          : require('../assets/image/chat_micphone_icon.svg')
          " class="speak-btn-mic" />
      </van-button>
    </div>
    <VideoTriggerPlayView v-if="showVideoPlayTriggerView" @dismissVideoTriggerPlay="dismissVideoTriggerPlayView"
      :showVideoUrl="showVideoUrl">
    </VideoTriggerPlayView>
    <EvaluationView ref="evaluationViewRef" v-show="showEvaluationView" :show="showEvaluationView"
      :evaluationList="evaluationList" @exitEvaluationView="exitEvaluationView" @voicePlayText="voicePlayText"
      @setReportId="setReportId">
    </EvaluationView>
    <InteractiveGuidanceView v-if="showInteractiveGuide" @closeInteractiveGuide="closeInteractiveGuide">
    </InteractiveGuidanceView>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, Ref, watch } from "vue";
import { showDialog, showToast } from "vant";
import EvaluationView from "./evaluationView.vue";
import UmiChat from "../components/umiChat.vue";
import OwnChat from "../components/ownChat.vue";
import ChatLottie from "../components/chatLottie.vue";
import UmiAudioInfoView from "../components/umiAudioInfoView.vue";
import UmiAudioListenLoadingView from "../components/umiAudioListenLoadingView.vue";
import VideoTriggerPlayView from "./videoTriggerPlayView.vue";
import InteractiveGuidanceView from "./interactiveGuidanceView.vue";
import { AxiosHeaders } from "axios";
import {
  BizReportArgs,
  BizStageReportArgs,
  linkardNet,
  ChatMenuItem
} from "@/net/linkardNet";
import { HttpResponse } from "@/net/http";
import { useRoute } from "vue-router";
import { buryingPointData } from "@/utils/buryingPointData";
import { uuid } from "vue3-uuid";
import SceneSwitchButton from "./sceneSwitch/sceneSwitchButton.vue";

const { userAgent } = navigator;
const isIOS = /(iPhone|iPad|iPod|iOS)/i.test(userAgent); // ios终端

interface BotAnswer {
  fromBot: boolean;
  fromAudio: boolean;
  triggerType: number;
  text: string;
  mediaUrl?: string;
  url?: string;
  scaleIdIdentify?: string;
}

const currentChatMax = 50;
const localSaveMax = 50;

export default defineComponent({
  props: {
    sdkToken: {
      type: String,
      default: "",
    },
    linkardId: {
      type: String,
      default: "",
    },
    hasInitService: {
      type: Boolean,
      default: false,
    },
    initUnityCallBack: {
      type: Boolean,
      default: false,
    },
    npcName: {
      type: String,
      default: "",
    },
    showChat: {
      type: Boolean,
      default: false,
    },
    micPermissions: {
      type: Number,
      default: 0,
    },
    sourceType: {
      type: Number,
      default: -1,
    },
    scaleValid: {
      type: Boolean,
      default: false,
    },
    greetingMsg: {
      type: Object,
      default: null,
    },
    scaleIconText: {
      type: String,
      default: "",
    },
    chatSceneIconText: {
      type: String,
      default: "",
    },
    storyIconText: {
      type: String,
      default: "",
    },
    chatMenuList: {
      type: Array,
      default: [],
    },
    reviewCourseIds: {
      type: Array,
      default: [],
    },
  },
  components: {
    UmiChat,
    OwnChat,
    ChatLottie,
    UmiAudioInfoView,
    UmiAudioListenLoadingView,
    VideoTriggerPlayView,
    EvaluationView,
    InteractiveGuidanceView,
    SceneSwitchButton,
  },
  setup(props, { emit }) {
    const route = useRoute();
    const list: Ref<BotAnswer[]> = ref([]);
    const currentBotAnswer: Ref<BotAnswer | null> = ref(null);
    const currentUserAsk: Ref<BotAnswer | null> = ref(null);
    const speakButtonSelect = ref(false);
    const loading = ref(false);
    let currentTextFromAudio = false;
    const showUmiAudioView = ref(false);
    const speakInfoSendState = ref(0);
    const message = ref("");
    const answering = ref(false);
    const showVideoUrl = ref("");
    const interactiveViewRef = ref();
    const hideInteractive = ref(false);

    let scrollHeight = 0;
    let audioRequstId = "";
    let voiceDuration = 0;
    let speakEndTimeStamp = 0;
    let recordLineBreakChar = "";
    let askRecordLineBreakChar = "";
    let richTextReg =
      /((https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|])|(www\.[\w-]+\.[a-z]{2,4})/g;
    let textTriggerList = [] as string[];
    let imgTriggerList = [] as string[];
    let videoTriggerList = [] as string[];
    let linkTriggerList = [] as string[];
    let tempBuffer = "";
    //触发词相关 1:文本 2:图片 3:视频 4:链接
    let triggerType = -1;
    let showVideoPlayTriggerView = ref(false);
    let validUrlList = [] as string[]; //当前会话的合法url list
    let botAnswerQueue = [] as string[];
    let userAudioMessageQueue = [] as string[];
    let curUserAudioMessageId = "";
    let isTriggerText = false;
    let splitMessageTimer = 0;
    let userAudioMessageTimer = 0;
    let speakSpressDownTimer = 0;
    let micPermissionStatus = ref(0);//0:未获取状态；1:已经获取状态；2:获取状态进行中；-1:状态被拒绝
    let interactiveId = 0;

    let reviewStep = ref(-1);// 1：请求总结课程，2：请求课程出题

    const showInteractiveGuide = ref(false);

    const audioTextRegex = /\[Text\]([^[]+)\[TextDone\]/g;
    const audioImageRegex = /\[Image\]([^[]+)\[ImageDone\]/g;
    const audioVideoRegex = /\[Video\]([^[]+)\[VideoDone\]/g;
    const audioLinkRegex = /\[Link\]([^[]+)\[LinkDone\]/g;
    const scaleIdRegex = /\[Scale\]([^[]+)\[ScaleDone\]/g;

    const speakCenterBtnRef = ref();
    let containerRect: DOMRect | null = null;
    const speakPointInside = ref(true);

    const iscallingUmi = ref(false);
    const webrtcConnect = ref(false);
    let interrupt = false;

    const scale = window.screen.width / 375.0;

    const rootFontSize = parseFloat(
      window.getComputedStyle(document.documentElement).fontSize
    );

    const chatAnchors = ref([
      scale <= 1.5
        ? Math.round(130 * (rootFontSize / 16.0))
        : Math.round(110 * (rootFontSize / 16.0)),
      scale <= 1.5
        ? Math.round(230 * (rootFontSize / 16.0))
        : Math.round(180 * (rootFontSize / 16.0)),
    ]);

    const anchorHeight = ref(chatAnchors.value[0]);

    const showEvaluationView = ref(false);
    let scaleIds = "";
    const scaleIdIdentify = ref("");
    let tempScaleIdentify = "";
    let curScaleReportId = "";

    const evaluationList: Ref<any[]> = ref([]);
    let evlAudioState = -1; //-1：未开始 1:开始语进行中 2:题目读题进行中 3:结束语进行中
    const evaluationViewRef = ref();
    const hasEvaluationList = ref(false);

    let hasSendGreetMsgAudio = false;
    let greetMsgAudioId = "";
    let resetHistory = 1; // 1未重置复习历史 2已重置复习历史但未告诉unity 3重置并且告诉了unity

    let tempLinkardId = "";

    // const handleVisibilityChange = () => {
    //   if (document.hidden) {
    //     emit("uninitCallbackMethod");
    //   } else {
    //   }
    // };

    // onMounted(() => {
    //   document.addEventListener('visibilitychange', handleVisibilityChange);
    // });

    // onUnmounted(() => {
    //   // document.removeEventListener('visibilitychange', handleVisibilityChange);
    // });

    const showChatMessageListMethod = () => {
      emit("showChatMessageListMethod");
    };

    const resetData = () => {
      message.value = "";
      scrollHeight = 0;
      answering.value = false;
      stopIntervalOutput();
      stopUserAudioMessageOutput();
      list.value = [];
      currentBotAnswer.value = null;
      currentUserAsk.value = null;
    };

    const handleKeyboard = async (event: KeyboardEvent) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        await toAnswer(message.value);
      }
    };

    const inputFocus = () => {
      const divElement = document.querySelector(".chat-container");
      if (divElement) {
        const divHeight = divElement.clientHeight;
        window.scrollTo(0, divHeight);
        handleScroll();
      }
    };

    const handleScroll = (instant?: boolean) => {
      setTimeout(
        () => {
          const listEles = document.getElementsByClassName("chat-list-content");
          if (listEles.length > 0) {
            const ele = listEles[0] as HTMLElement;
            const tempHeight = ele.scrollHeight;
            if (Math.abs(tempHeight - scrollHeight) < 10) {
              return;
            }

            scrollHeight = tempHeight;
            let tempBehavior: ScrollBehavior | undefined;
            if (instant) {
              tempBehavior = "instant" as ScrollBehavior;
            } else {
              tempBehavior = "smooth" as ScrollBehavior;
            }
            const options: ScrollToOptions = {
              left: 0,
              top: tempHeight,
              behavior: tempBehavior,
            };
            ele.scrollTo(options);
          }
        },
        instant ? 0 : 100
      );
    };

    const speakButtonClick = () => {
      if (!props.sdkToken || route.query.preview) {
        return;
      }
      speakButtonSelect.value = !speakButtonSelect.value;
      if (!speakButtonSelect.value) {
        scrollHeight = 0;
        handleScroll(true);
      }

      showUmiAudioView.value = false;
    };

    const speakCenterBttonClick = (event: TouchEvent) => {
      event.preventDefault();
      interrupt = false;
      if (
        reviewStep.value != -1 || !props.sdkToken ||
        !props.hasInitService ||
        !props.initUnityCallBack ||
        !webrtcConnect.value
      ) {
        return;
      }

      if (props.micPermissions != 1) {
        if (isIOS) {
          micPermissionStatus.value = 2;
          emit("getMicPermissions");
        } else {
          if (props.micPermissions == 0) {
            micPermissionStatus.value = 2;
            emit("getMicPermissions");
          } else {
            showDialog({
              message: "您拒绝了麦克风授权，请关闭页面重新进入并授权",
              confirmButtonText: "知道了",
            }).then(() => { });
          }
        }
        return;
      }

      if (speakInfoSendState.value === 3 || speakInfoSendState.value === 4) {
        return;
      } else if (speakInfoSendState.value === 1) {
        stopCountDownMethod();
        return;
      }

      const currentTime = getCurrentTime();
      voiceDuration = currentTime;
      audioRequstId = tempLinkardId + currentTime.toString();
      buryingPointData.chatRequestId = audioRequstId;
      emit("startCaptureAudioMethod", audioRequstId);
      containerRect = speakCenterBtnRef.value?.getBoundingClientRect() ?? null;
      showUmiAudioView.value = true;
      speakInfoSendState.value = 1;
      speakSpressDownCountTimer();
      answering.value = true;

      bizStageReportRequst("startCaptureAudio");
    };

    const closeUmiSpeak = () => {
      speakInfoSendState.value = 0;
      showUmiAudioView.value = false;
      interrupt = true;
      emit("interruptPlayVoiceMethod");
      bizStageReportRequst("interruptPlayVoice");
    };

    const stopCountDownMethod = () => {
      if (
        speakInfoSendState.value === 3 ||
        speakInfoSendState.value === 4 ||
        speakInfoSendState.value === 0
      ) {
        return;
      }

      stopUserAudioMessageOutput();
      botAnswerQueue.push("done");

      //手指上划 取消发送
      if (speakPointInside.value == false) {
        if (speakSpressDownTimer) {
          clearInterval(speakSpressDownTimer);
          speakSpressDownTimer = 0;
        }
        emit("stopCaptureAudioMethod", false);
        bizStageReportRequst("stopCaptureAudio");
        closeUmiSpeak();
        speakInfoSendState.value = 0;
        showUmiAudioView.value = false;
        speakPointInside.value = true;
        answering.value = false;
        return;
      }

      showUmiAudioView.value = false;
      speakInfoSendState.value = 2;
      var timeIsTooShort = true;
      const currentTime = getCurrentTime();
      speakEndTimeStamp = currentTime;
      const tempVoiceDuration = currentTime - voiceDuration;
      if (tempVoiceDuration < 500) {
        if (speakSpressDownTimer) {
          clearInterval(speakSpressDownTimer);
          speakSpressDownTimer = 0;
        }
        timeIsTooShort = false;
        showToast({
          message: "说话时间太短",
          position: "middle",
        });
        setTimeout(() => {
          emit("stopCaptureAudioMethod", timeIsTooShort);
          bizStageReportRequst(`stopCaptureAudio-:${timeIsTooShort}`);
          speakInfoSendState.value = 0;
          showUmiAudioView.value = false;
        }, 100);
        answering.value = false;
        return;
      } else {
        emit("stopCaptureAudioMethod", timeIsTooShort);
        bizStageReportRequst(`stopCaptureAudio-:${timeIsTooShort}`);
      }
      setTimeout(() => {
        showUmiAudioView.value = true;
        speakInfoSendState.value = 3;
      }, 500);
    };

    //开始采集之后,如果超过500ms,就说明这个不算太短(前端的逻辑，和后端无关)，则告诉unity，这段录制可以用
    const speakSpressDownCountTimer = () => {
      speakSpressDownTimer = setInterval(() => {
        emit("setSendAudioEnableMethod", true);
        bizStageReportRequst("setSendAudioEnable");
        clearInterval(speakSpressDownTimer);
        speakSpressDownTimer = 0;
      }, 500);
    };

    const handleSpeakCenterButtonEndClick = (event: TouchEvent) => {
      event.preventDefault();
      stopCountDownMethod();
    };

    const updatePosition = (event: TouchEvent) => {
      event.preventDefault();
      if (!containerRect) return;

      const touch = event.touches[0];
      const x = touch.clientX;
      const y = touch.clientY;
      if (
        x >= containerRect.left &&
        x <= containerRect.right &&
        y >= containerRect.top &&
        y <= containerRect.bottom
      ) {
        speakPointInside.value = true;
      } else {
        speakPointInside.value = false;
      }
    };

    const receiveChatResponse = (errorCode: number, requestId: string) => {
      setReviewCourseCallbackMethod();
      if (!requestId || requestId != audioRequstId) return;
      const timeDifference = getCurrentTime() - speakEndTimeStamp;

      const args: BizReportArgs = {
        bizId: requestId,
        status: 0,
        errorMsg: "success",
        elapse: timeDifference,
      };

      if (errorCode !== 2000000) {
        args.status = 1;
        args.errorMsg = errorCode.toString();
        answering.value = false;

        if (errorCode == -4 && !showEvaluationView) {
          botAnswerQueue.push("done");
          handleAudioKeyWorld("刚刚网络波动了，请再重新说一遍吧～");
        }

        if (errorCode == -3 || errorCode == -4) {
          audioPlayResponseTimerMethod();
          speakInfoSendState.value = 0;
          showUmiAudioView.value = false;
          answering.value = false;
        }
      }
      bizResultReportRequst(args);
    };
    const audioPlayResponse = (playCode: number) => {
      if (playCode === 1) {
        speakInfoSendState.value = 4;
        showUmiAudioView.value = true;

        if (
          greetMsgAudioId &&
          greetMsgAudioId == audioRequstId &&
          hasSendGreetMsgAudio
        ) {
          updateChatText(greetMsgAudioId, props.greetingMsg.text);
          storageChatMessage();
        }
      } else if (playCode === 0) {
        speakInfoSendState.value = 0;
        showUmiAudioView.value = false;
        answering.value = false;
        if (
          greetMsgAudioId &&
          greetMsgAudioId == audioRequstId &&
          hasSendGreetMsgAudio
        ) {
          showGreetingMsgMedia();
          greetMsgAudioId = "";
          hasSendGreetMsgAudio = false;
        }

        if (evlAudioState == 1) {
          evlAudioState = 2;
          evaluationViewRef.value.evaluationStartPlayEnd();
        } else if (evlAudioState == 3) {
          evlAudioState = -1;
          evaluationViewRef.value.evaluationStartPlayEnd();
          if (curScaleReportId) {
            emit("showEvaluationDetailView", curScaleReportId, 2);
          }
        }
        storageChatMessage();
        if(props.storyIconText!=''){
          interactiveViewRef.value.nextAutoVoicePage();
        }
      }
    };

    const audioPlayResponseTimerMethod = () => {
      speakInfoSendState.value = 0;
      showUmiAudioView.value = false;
    };

    const updateChatText = (requestId: string, message: string) => {
      if (!message) {
        bizStageReportRequst("messageEmty");
        return;
      }
      botAnswerQueue.push("audioText");
      var tempMessage = message;
      if (message.includes("\n")) {
        const regExp = new RegExp("\n", "g");
        tempMessage = tempMessage.replace(regExp, "<br>");
      }
      handleAudioKeyWorld(tempMessage);
    };

    //用户语音转文字
    const updateSTTTextMethod = (requestId: string, message: string) => {
      var tempMessage = message;
      if (message.includes("\n")) {
        const regExp = new RegExp("\n", "g");
        tempMessage = tempMessage.replace(regExp, "<br>");
      }
      showUserAudioMessageOutput(tempLinkardId, tempMessage);
      linkardNet.buryingPointChatDataReport(
        tempLinkardId,
        "user",
        "vocie",
        tempMessage,
        route.fullPath,
        buryingPointData.GetBuryingPointDataInfo()
      );
    };

    const webrtcConnectionStateMethod = (state: number) => {
      if (state == 2) {
        webrtcConnect.value = true;
        setWelcomMessage();
        if (props.reviewCourseIds && props.reviewCourseIds != undefined) {
          setReviewCourseCallbackMethod();
        }
      } else {
        webrtcConnect.value = false;
      }
    };

    const setReviewCourseCallbackMethod = () => {
      if (resetHistory == 1) {
        resetHistory = 2;
        emit("registerSetReviewCourseCallbackMethod", props.reviewCourseIds, true);
      } else if (resetHistory == 2) {
        resetHistory = 3;
        emit("registerSetReviewCourseCallbackMethod", props.reviewCourseIds, false);
      }
    };

    const handleAudioKeyWorld = (message: string) => {
      const isFailure = message.endsWith("[Failure]");
      if (isFailure) {
        message = "我刚才走神了，请再来一遍吧！";
      }

      const isCompleted = message.endsWith("[Done]");

      if (isCompleted) {
        message = message.slice(0, -6);
      }
      const isIllegal = message.endsWith("[Illegal]");
      if (isIllegal) {
        message = message.slice(0, -9);
      }

      if (message.indexOf("[Clear]") != -1) {
        const messageList = message.split("[Clear]");
        message = messageList[1];
      }

      const textMatch = message.match(audioTextRegex);
      const imageMatch = message.match(audioImageRegex);
      const videoMatch = message.match(audioVideoRegex);
      const linkMatch = message.match(audioLinkRegex);
      const scaleIdMatch = message.match(scaleIdRegex);

      //心理量表
      if (scaleIdMatch) {
        tempScaleIdentify = uuid.v4();
        scaleIdIdentify.value = tempScaleIdentify;
        scaleIdMatch.forEach((itemStr) => {
          scaleIds = itemStr.replace(/\[Scale\]|\[ScaleDone\]/g, "");
          message = message.replace(itemStr, "");
        });

        if (!message) {
          gotoGetScaleListRequest(scaleIds);
        }
      }

      isTriggerText = false;

      if (!textMatch && !imageMatch && !videoMatch && !linkMatch) {
        sendIntervalOutput(
          tempLinkardId,
          message,
          isCompleted || isIllegal || isFailure,
          true
        );
      } else {
        if (textMatch) {
          textMatch.forEach((itemStr) => {
            const tempItemStr = itemStr.replace(/\[Text\]|\[TextDone\]/g, "");
            sendIntervalOutput(tempLinkardId, tempItemStr, true, true);
            message = message.replace(itemStr, "");
          });
        }

        if (imageMatch) {
          imageMatch.forEach((itemStr) => {
            const tempItemStr = itemStr.replace(/\[Image\]|\[ImageDone\]/g, "");
            var dict = {
              fromBot: true,
              fromAudio: false,
              triggerType: 2,
              mediaUrl: tempItemStr,
              text: ``,
            };
            list.value.push(dict);
            handleScroll();
            storageChatMessage();
            message = message.replace(itemStr, "");
          });
        }

        if (videoMatch) {
          videoMatch.forEach((itemStr) => {
            const tempItemStr = itemStr.replace(/\[Video\]|\[VideoDone\]/g, "");
            var dict = {
              fromBot: true,
              fromAudio: false,
              triggerType: 3,
              mediaUrl: tempItemStr,
              text: ``,
            };
            list.value.push(dict);
            storageChatMessage();
            message = message.replace(itemStr, "");
          });
        }

        if (linkMatch) {
          isTriggerText = true;
          linkMatch.forEach((itemStr) => {
            const tempItemStr = itemStr.replace(/\[Link\]|\[LinkDone\]/g, "");
            sendIntervalOutput(tempLinkardId, tempItemStr, true, true);
            message = message.replace(itemStr, "");
          });
        }
        if (message) {
          sendIntervalOutput(tempLinkardId, message, true, true);
        }
      }
    };

    const toAnswer = async (text: string) => {
      if (answering.value) {
        // 正在回答中，不可再次回答
        // console.warn('Is answering...');
        return;
      }

      if (!text) return;
      message.value = "";
      answering.value = true;
      const botAnswer: BotAnswer = {
        fromBot: false,
        fromAudio: false,
        triggerType: -1,
        text: text,
      };
      list.value.push(botAnswer);
      const chatRequestId = tempLinkardId + getCurrentTime().toString();
      buryingPointData.chatRequestId = chatRequestId;
      linkardNet.buryingPointChatDataReport(
        tempLinkardId,
        "user",
        "word",
        text,
        route.fullPath,
        buryingPointData.GetBuryingPointDataInfo()
      );
      storageChatMessage();
      if (list.value.length > currentChatMax) {
        // 删除前面的对话
        list.value.splice(0, 1);
      }

      handleScroll();
      await getAnswer(tempLinkardId, text);
      answering.value = false;
      // handleScroll();
    };

    const removeCurrentBotAnswer = (npcId: string) => {
      if (tempLinkardId === npcId) {
        botAnswerQueue = [];
        if (currentBotAnswer.value != null) {
          // 清除回答
          currentBotAnswer.value.text = "";
        }
      }
    };
    const appendCurrentBotAnswer = (
      text: string,
      fromAudio: boolean,
      fromBot: boolean
    ): boolean => {
      let change = false;
      if (currentBotAnswer.value == null) {
        recordLineBreakChar = "";
        const botAnswer: BotAnswer = {
          fromBot: fromBot,
          fromAudio: fromAudio,
          triggerType: -1,
          text: "",
          scaleIdIdentify: "",
        };
        currentBotAnswer.value = botAnswer;
        list.value.push(currentBotAnswer.value);
        change = true;
      }

      if (tempScaleIdentify) {
        currentBotAnswer.value.scaleIdIdentify = tempScaleIdentify
          ? tempScaleIdentify
          : "";
        tempScaleIdentify = "";
      }

      if (text == "audioText") return false;

      if (text) {
        // 换行标签:<br>的四个字符，如果挨个输出，气泡框会抖动，因此先记录下来，字符完全输出完了之后，再加入
        if (text == "<") {
          recordLineBreakChar = recordLineBreakChar + text;
          return false;
        } else if (
          recordLineBreakChar.indexOf("<") !== -1 &&
          recordLineBreakChar.length < 4
        ) {
          recordLineBreakChar = recordLineBreakChar + text;
          return false;
        } else if (
          recordLineBreakChar.indexOf("<") !== -1 &&
          (text == ">" || recordLineBreakChar.length >= 4)
        ) {
          recordLineBreakChar = recordLineBreakChar + text;
        }

        var addText = text;
        if (recordLineBreakChar.length > 0) {
          addText = recordLineBreakChar;
        }
        currentBotAnswer.value.text += addText;
        recordLineBreakChar = "";
        change = true;
      }

      return change;
    };

    const completeBotAnswer = () => {
      if (reviewStep.value == 1) {
        answering.value = true;
        reviewStep.value = 2;
        getAnswer(tempLinkardId, "", '<|COMMAND|>课程出题');
      } else if (reviewStep.value == 2) {
        answering.value = false;
        reviewStep.value = -1;
      }
      if (currentBotAnswer.value != null) {
        // 回答完毕
        processingRichText(currentBotAnswer.value.text);
        if (!currentBotAnswer.value.text) {
          // 如果回答为空，整个删除
          list.value = list.value.filter((a) => a !== currentBotAnswer.value);
        }
        currentBotAnswer.value = null;
        storageChatMessage();
      }
    };

    const appendCurrentUserAsk = (
      text: string,
      fromAudio: boolean,
      fromBot: boolean
    ): boolean => {
      let change = false;
      if (currentUserAsk.value == null) {
        askRecordLineBreakChar = "";
        const botAnswer: BotAnswer = {
          fromBot: fromBot,
          fromAudio: fromAudio,
          triggerType: -1,
          text: "",
        };
        currentUserAsk.value = botAnswer;
        list.value.push(currentUserAsk.value);
        change = true;
      }

      if (text == "audioText") return false;

      if (text) {
        // 换行标签:<br>的四个字符，如果挨个输出，气泡框会抖动，因此先记录下来，字符完全输出完了之后，再加入
        if (text == "<") {
          askRecordLineBreakChar = askRecordLineBreakChar + text;
          return false;
        } else if (
          askRecordLineBreakChar.indexOf("<") !== -1 &&
          askRecordLineBreakChar.length < 4
        ) {
          askRecordLineBreakChar = askRecordLineBreakChar + text;
          return false;
        } else if (
          askRecordLineBreakChar.indexOf("<") !== -1 &&
          (text == ">" || askRecordLineBreakChar.length >= 4)
        ) {
          askRecordLineBreakChar = askRecordLineBreakChar + text;
        }

        var addText = text;
        if (askRecordLineBreakChar.length > 0) {
          addText = askRecordLineBreakChar;
        }
        currentUserAsk.value.text += addText;
        askRecordLineBreakChar = "";
        change = true;
      }

      return change;
    };

    const completeCurrentUserAsk = () => {
      if (currentUserAsk.value != null) {
        if (!currentUserAsk.value.text) {
          list.value = list.value.filter((a) => a !== currentUserAsk.value);
        }
        currentUserAsk.value = null;
        stopUserAudioMessageOutput();
      }
      storageChatMessage();
    };

    const processingRichText = (richText: string) => {
      if (!richText.includes("http") && !richText.includes("www")) return;

      let urlList: RegExpMatchArray | null = richText.match(richTextReg);
      let selectUrlList = [] as string[];
      if (!isTriggerText) {
        if (validUrlList.length <= 0 || !urlList) return;
        selectUrlList = urlList.filter((item) => validUrlList.includes(item));
      } else {
        selectUrlList = urlList ?? [];
      }

      if (!selectUrlList || selectUrlList.length <= 0) return;

      const uniqueUrlList = [...selectUrlList];
      const encodeURLList = [];

      for (const matchedUrl of uniqueUrlList) {
        const dict = { protocol: "", text: "" };
        let toMatchedUrl = matchedUrl;
        if (toMatchedUrl.startsWith("www")) {
          dict.protocol = encodeURIComponent("http://");
        }
        dict.text = encodeURIComponent(toMatchedUrl);
        encodeURLList.push(dict);
      }

      let tempRichText = encodeURIComponent(richText);
      for (const urlDict of encodeURLList) {
        const tempRegExp = new RegExp(urlDict.text, "g");
        tempRichText = tempRichText.replace(
          tempRegExp,
          `<a href="${urlDict.protocol + urlDict.text
          }" style="color: #0563C1;" target="_blank">${urlDict.text}</a>`
        );
      }

      if (currentBotAnswer.value != null) {
        currentBotAnswer.value.text = decodeURIComponent(tempRichText);
      }
    };

    const sendIntervalOutput = (
      npcId: string,
      buffer: string,
      isCompleted: boolean,
      fromAudio: boolean
    ) => {
      currentTextFromAudio = fromAudio;
      // 40ms 输出一个字符
      if (tempLinkardId !== npcId) {
        // console.warn('npc切换了');
        return;
      }

      // 一个字一个字打印出来
      if (buffer.length > 0) {
        botAnswerQueue.push(...buffer.split(""));
      } else if (!isCompleted) {
        // 新起一行
        botAnswerQueue.push("");
      }

      if (isCompleted) {
        //发送完成，进行埋点
        let contentStr = "";
        if (buffer && currentBotAnswer.value) {
          contentStr = currentBotAnswer.value.text + buffer;
        } else if (currentBotAnswer.value) {
          contentStr = currentBotAnswer.value.text;
        } else if (buffer) {
          contentStr = buffer;
        }
        if (botAnswerQueue.length > 0) {
          const lastContentStr = botAnswerQueue.join("");
          contentStr += lastContentStr;
        }

        if (contentStr) {
          linkardNet.buryingPointChatDataReport(
            tempLinkardId,
            "robot",
            fromAudio ? "vocie" : "word",
            contentStr,
            route.fullPath,
            buryingPointData.GetBuryingPointDataInfo()
          );
        }
        botAnswerQueue.push("done");
      }

      if (splitMessageTimer === 0) {
        const func = () => {
          try {
            if (botAnswerQueue.length > 0) {
              // 每次取第一个字符
              loading.value = false;
              const [char] = botAnswerQueue.splice(0, 1);
              if (char === "done") {
                // 输出结束
                completeBotAnswer();
              } else if (char === "audioText") {
                appendCurrentBotAnswer(char, true, true);
                handleScroll();
              } else if (appendCurrentBotAnswer(char, false, true)) {
                handleScroll();
              }
            } else {
              if (!currentTextFromAudio) {
                loading.value = true;
              } else {
                loading.value = false;
                if (interrupt) {
                  interrupt = false;
                  botAnswerQueue.push("done");
                }
              }
            }
          } catch { }
        };
        splitMessageTimer = setInterval(func, 40);
      }
    };

    const showUserAudioMessageOutput = (npcId: string, buffer: string) => {
      // 40ms 输出一个字符
      if (tempLinkardId !== npcId) {
        // console.warn('npc切换了');
        return;
      }
      // 一个字一个字打印出来
      if (buffer.length > 0) {
        userAudioMessageQueue.push(...buffer.split(""));
      }
      //  else if (!isCompleted) {
      //   // 新起一行
      //   userAudioMessageQueue.push("");
      // }

      // if (isCompleted) {
      //   userAudioMessageQueue.push("done");
      // }

      if (userAudioMessageTimer === 0) {
        const askFunc = () => {
          try {
            if (userAudioMessageQueue.length > 0) {
              const [char] = userAudioMessageQueue.splice(0, 1);
              if (char === "done") {
                // 输出结束
                completeCurrentUserAsk();
              } else {
                appendCurrentUserAsk(char, true, false);
                handleScroll();
              }
            } else {
            }
          } catch { }
        };
        userAudioMessageTimer = setInterval(askFunc, 40);
      }
    };

    const stopIntervalOutput = () => {
      // 清除缓存中的数据
      botAnswerQueue = [];
      if (splitMessageTimer) {
        clearInterval(splitMessageTimer);
        splitMessageTimer = 0;
      }
    };

    const stopUserAudioMessageOutput = () => {
      // 清除缓存中的数据
      userAudioMessageQueue = [];
      currentUserAsk.value = null;
      if (userAudioMessageTimer) {
        clearInterval(userAudioMessageTimer);
        userAudioMessageTimer = 0;
      }
    };

    const getAnswer = async (npcId: string, text: string, reviewInstr: string | null = null) => {
      let data = {

      };
      if (props.reviewCourseIds && props.reviewCourseIds != undefined && props.reviewCourseIds.length) {
        if (!resetHistory) {
          resetHistory = 3;
          data = {
            linkardId: props.linkardId,
            npcId:npcId,
            resetHistory: true,
            text: reviewInstr ? reviewInstr : text,
            dynamicQuery: {
              courseIds: props.reviewCourseIds
            }
          };
        } else {
          data = {
            linkardId: props.linkardId,
            npcId:npcId,
            text: reviewInstr ? reviewInstr : text,
            dynamicQuery: {
              courseIds: props.reviewCourseIds
            }
          };
        }

      } else {
        data = {
          linkardId: props.linkardId,
          npcId:npcId,
          text: reviewInstr ? reviewInstr : text,
        };
      }
      const chatServerUrl = process.env.VUE_APP_SERVER_URL;
      let reader: ReadableStreamDefaultReader<string> | undefined;
      if (!reviewInstr)
      {
        botAnswerQueue.push("done");
        sendIntervalOutput(npcId, "", false, false);
      }
      try {
        const headers = new AxiosHeaders();
        headers.set("T-Validate-Token", sessionStorage.getItem("token"));
        headers.set("Content-Type", "application/json");
        const response = await fetch(`${chatServerUrl}/v2/npc/chat`, {
          method: "POST",
          headers,
          body: JSON.stringify(data),
        });

        if (!response.body) {
          console.error("Response body is null.");
          sendIntervalOutput(npcId, "发呆中...", true, false);
          return;
        }

        reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
      } catch (e) {
        console.error("fetch chatserver error:", e);
        sendIntervalOutput(npcId, "发呆中...", true, false);
        return;
      }

      validUrlList = [];
      isTriggerText = false;
      let hasScaleIds = false; //是否有量表触发id
      let hasOutDone = false;
      let chunk = "";
      while (tempLinkardId === npcId) {
        let value = "";
        let done = false;
        try {
          const result = await reader.read();
          value = result.value ?? "";
          done = result.done ?? false;
        } catch (e) {
          console.error("reader.read error:", e);
          sendIntervalOutput(npcId, "", true, false);
          sendIntervalOutput(npcId, "发呆中...", true, false);
          return;
        }

        if (done) {
          // 正常结束
          sendIntervalOutput(npcId, "", true, false);
          return;
        }

        const chunks = (chunk + value).split("data:");
        chunk = "";
        let buffer = "";
        // 截取一个完整的json片段并解析出其中的文字到buffer中
        outerLoop: for (var i = 0; i < chunks.length; i++) {
          const item = chunks[i].trim();
          if (!item) {
            // item == ''
            continue outerLoop;
          }
          switch (item) {
            case "[Done]":
            case "[Illegal]":
              hasOutDone = true;
              // if (reviewInstr == '<|COMMAND|>总结课程') {
              //   getAnswer(npcId, "", '<|COMMAND|>课程出题');
              // }
              break outerLoop;

            case "[TextDone]":
            case "[LinkDone]":
            case "[ImageDone]":
            case "[VideoDone]":
              handleTriggerWord(npcId, item);
              continue outerLoop;

            case "[Scale]":
              hasScaleIds = true;
              scaleIds = "";
              continue outerLoop;

            case "[ScaleDone]":
              tempScaleIdentify = uuid.v4();
              scaleIdIdentify.value = tempScaleIdentify;
              hasScaleIds = false;
              continue outerLoop;

            case "[Text]":
            case "[Link]":
            case "[Image]":
            case "[Video]":
              isTriggerText = true;
              handleTriggerWord(npcId, item);
              continue outerLoop;
            case "[Failure]":
              buffer = "我刚才走神了，请再来一遍吧！";
              break outerLoop;
            case "[Clear]":
              buffer = "";
              removeCurrentBotAnswer(npcId);
              continue outerLoop;
          }

          if (hasScaleIds) {
            try {
              const ch = JSON.parse(item).chunk;
              if (ch) {
                scaleIds += ch;
                continue;
              }
            } catch { }
          } else {
            if (triggerType == -1) {
              try {
                if (!item.includes("valid_url")) {
                  const ch = JSON.parse(item).chunk;
                  if (ch) {
                    buffer += ch;
                    continue;
                  }
                } else {
                  validUrlList = JSON.parse(item).valid_url;
                  if (validUrlList) {
                    continue;
                  }
                }
              } catch { }

              if (i !== chunks.length - 1) {
                // illegal chunk，结束本次对话
                console.warn("illegal chunk:", item);
                break;
              }

              // 最后一个chunk数据可能不完整，先不处理，留到和下次读入的片段一起处理
              chunk = item;
            } else {
              try {
                const ch = JSON.parse(item).chunk;
                if (ch) {
                  tempBuffer += ch;
                  continue;
                }
              } catch { }
            }
          }
        }

        //处理换行问题
        if (buffer.includes("\n")) {
          const regExp = new RegExp("\n", "g");
          buffer = buffer.replace(regExp, "<br>");
        }

        if (
          !currentBotAnswer.value?.text &&
          !buffer &&
          hasOutDone &&
          scaleIds
        ) {
          gotoGetScaleListRequest(scaleIds);
        }
        if (buffer) {
          sendIntervalOutput(npcId, buffer, false, false);
        }
      }
    };

    const handleTriggerWord = (npcId: string, guardText: string) => {
      if (triggerType == 1) {
        textTriggerList.push(tempBuffer);
        tempBuffer = "";
      } else if (triggerType == 2) {
        imgTriggerList.push(tempBuffer);
        tempBuffer = "";
      } else if (triggerType == 3) {
        videoTriggerList.push(tempBuffer);
        tempBuffer = "";
      } else if (triggerType == 4) {
        linkTriggerList.push(tempBuffer);
        tempBuffer = "";
      }
      switch (guardText) {
        case "[Text]":
          triggerType = 1;
          break;
        case "[Image]":
          triggerType = 2;
          break;
        case "[Video]":
          triggerType = 3;
          break;
        case "[Link]":
          triggerType = 4;
          break;
        case "[Done]":
        case "[TextDone]":
        case "[ImageDone]":
        case "[VideoDone]":
        case "[LinkDone]":
        case "[Illegal]":
          triggerType = -1;
          tempBuffer = "";

          textTriggerList.forEach((itemStr) => {
            if (itemStr.includes("\n")) {
              var regExp = new RegExp("\n", "g");
              itemStr = itemStr.replace(regExp, "<br>");
            }
            sendIntervalOutput(npcId, itemStr, true, false);
          });
          imgTriggerList.forEach((itemStr) => {
            const botAnswer: BotAnswer = {
              fromBot: true,
              fromAudio: false,
              triggerType: 2,
              mediaUrl: itemStr,
              text: ``,
            };
            list.value.push(botAnswer);
            linkardNet.buryingPointChatDataReport(
              tempLinkardId,
              "robot",
              "word",
              itemStr,
              route.fullPath,
              buryingPointData.GetBuryingPointDataInfo()
            );
            storageChatMessage();
          });
          imgTriggerList = [];

          videoTriggerList.forEach((itemStr) => {
            // var dict = { fromBot: true, fromAudio: false, triggerType: 3, mediaUrl: itemStr, text: `<div style="height: 100%;width: 100%;border-radius: 0.5rem;border: 0.1rem solid #f0f0f0;box-sizing: border-box;object-fit: cover;"></div>` };
            const botAnswer: BotAnswer = {
              fromBot: true,
              fromAudio: false,
              triggerType: 3,
              mediaUrl: itemStr,
              text: ``,
            };
            list.value.push(botAnswer);
            linkardNet.buryingPointChatDataReport(
              tempLinkardId,
              "robot",
              "word",
              itemStr,
              route.fullPath,
              buryingPointData.GetBuryingPointDataInfo()
            );
            storageChatMessage();
          });
          videoTriggerList = [];

          linkTriggerList.forEach((itemStr) => {
            if (itemStr.includes("\n")) {
              var regExp = new RegExp("\n", "g");
              itemStr = itemStr.replace(regExp, "<br>");
            }
            sendIntervalOutput(npcId, itemStr, true, false);
          });

          // if (textTriggerList.length <= 0 || linkTriggerList.length <= 0) {
          //   sendIntervalOutput(npcId, "", true, false);
          //   completeBotAnswer();
          // }
          textTriggerList = [];
          linkTriggerList = [];
          return;
      }
    };

    const bizResultReportRequst = async (params: BizReportArgs) => {
      try {
        await linkardNet.bizResultReport(params, props.sdkToken);
      } catch (e) { }
    };

    const bizStageReportRequst = async (stageInfo: string) => {
      try {
        const args: BizStageReportArgs = {
          bizId: audioRequstId ? audioRequstId : getCurrentTime().toString(),
          status: 0,
          errorMsg: "success",
          stage: stageInfo,
          npcId: tempLinkardId,
        };
        await linkardNet.bizStageReport(args, props.sdkToken);
      } catch (e) { }
    };

    const goBack = () => {
      emit("closeChatView");
    };

    const storageChatMessage = async () => {
      if (showEvaluationView.value) return; //如果显示了量表内容，则不显示
      if (list.value.length > 0) {
        // 获取数组的最后localSaveMax个元素
        var lastEightMessages = list.value.slice(-localSaveMax);
        // 将子数组转换为 JSON 字符串
        var chatMessage = JSON.stringify(lastEightMessages);

        const keys = Object.keys(localStorage);
        const filteredArray = keys.filter((element) =>
          element.includes(`${tempLinkardId}_chatMessage`)
        );
        filteredArray.forEach((element) => {
          localStorage.removeItem(element);
        });

        // 保存到本地存储
        localStorage.setItem(
          `${getCurrentTime().toString()}_${tempLinkardId}_chatMessage`,
          chatMessage
        );
        lastEightMessages = [];
        chatMessage = "";
      }
    };

    const dismissVideoTriggerPlayView = () => {
      showVideoPlayTriggerView.value = false;
    };

    const showVideoPaly = (url: string) => {
      showVideoUrl.value = url;
      showVideoPlayTriggerView.value = true;
    };

    const getCurrentTime = () => {
      return new Date().getTime(); // 获取当前时间戳
    };

    const callUmiClick = () => {
      iscallingUmi.value = !iscallingUmi.value;
    };

    watch(
      () => props.showChat,
      (newValue, oldValue) => {
        if (newValue) {
          buryingPointData.chatId = uuid.v4();
          if (list.value.length === 0) {
            // loadLocalMessage();
            setWelcomMessage();
          } else {
            handleScroll(true);
          }
        }
      }
    );

    watch(
      () => props.sourceType,
      (newValue, oldValue) => {
        if (newValue == 1) {
          speakButtonSelect.value = true;
        } else {
          speakButtonSelect.value = false;
        }
      }
    );

    watch(
      () => props.linkardId,
      (newValue, oldValue) => {
        if (newValue) {
          tempLinkardId = props.linkardId
          interactiveViewRef.value.setLinkardId(tempLinkardId);
          resetData();
          if (props.showChat) {
            evaluationListRequest();
          }
          if (props.showChat && list.value.length === 0) {
            buryingPointData.chatId = uuid.v4();
            // loadLocalMessage();
            // setWelcomMessage();
            sendReviewScript();
          }
        }
      }
    );

    watch(
      () => props.greetingMsg,
      (newValue, oldValue) => {
        if (newValue) {
          setWelcomMessage();
        }
      }
    );

    watch(
      () => props.sdkToken,
      (newValue, oldValue) => {
        sendReviewScript();
      }
    );

    watch(
      () => props.reviewCourseIds,
      (newValue, oldValue) => {
        if (newValue && newValue.length > 0 && webrtcConnect.value) {
          setReviewCourseCallbackMethod();
        }
        sendReviewScript();
      }
    );

    watch(
      () => props.hasInitService,
      (newValue, oldValue) => {
        if (newValue) {
          setWelcomMessage();
        }
      }
    );

    watch(
      () => props.initUnityCallBack,
      (newValue, oldValue) => {
        if (newValue) {
          setWelcomMessage();
        }
      }
    );

    watch(
      () => props.micPermissions,
      (newValue, oldValue) => {
        micPermissionStatus.value = newValue;
      }
    );

    const sendReviewScript = () => {
      if (props.reviewCourseIds && props.reviewCourseIds.length > 0 && props.sdkToken && tempLinkardId) {
        answering.value = true;
        reviewStep.value = 1;
        getAnswer(tempLinkardId, "", '<|COMMAND|>总结课程');
      } else if ((!props.reviewCourseIds || props.reviewCourseIds.length <= 0) && props.sdkToken && tempLinkardId) {
        setWelcomMessage();
      }
    };

    const vanFloatingPanelClick = () => {
      if (anchorHeight.value == chatAnchors.value[0]) {
        anchorHeight.value = chatAnchors.value[1];
      } else {
        anchorHeight.value = chatAnchors.value[0];
      }
    };

    const getScaleListRequest = (scaleIdStr: string) => {
      linkardNet.getScaleList(tempLinkardId, scaleIdStr).then(
        (response) => {
          showEvaluationView.value = true;
          if (response && response.list) {
            response.list.forEach((item: any) => {
              if (item.desc.includes("\n")) {
                const regExp = new RegExp("\n", "g");
                item.desc = item.desc.replace(regExp, "<br>");
              }
            });
            evaluationList.value = response.list;
          }
        },
        (ex) => {
          const error = ex as HttpResponse<any>;
          showToast(error.message);
        }
      );
    };

    //如果没有文本内容，只有[Scale]...[ScaleDone][Done],则触发词直接触发心里量表
    const gotoGetScaleListRequest = (scaleIdStr: string) => {
      linkardNet.getScaleList(tempLinkardId, scaleIdStr).then(
        (response) => {
          showEvaluationView.value = true;
          if (response && response.list) {
            response.list.forEach((item: any) => {
              if (item.desc.includes("\n")) {
                const regExp = new RegExp("\n", "g");
                item.desc = item.desc.replace(regExp, "<br>");
              }
            });
            scaleIdIdentify.value = "";
            scaleIds = "";
            evaluationViewRef.value.gotoStartEvaluation(response.list[0].id);
          }
        },
        (ex) => {
          const error = ex as HttpResponse<any>;
          showToast(error.message);
        }
      );
    };

    const showEvaluationViewClick = () => {
      curScaleReportId = "";
      getScaleListRequest("");
    };

    const showInteractiveViewClick = (id:number) => {
      interactiveId = id;
      console.log(props.linkardId);
      hideInteractive.value = true;
      interactiveViewRef.value.setSceneList(id);
    };

    const hideInteractiveViewClick = () => {
      if(interactiveId==2&&speakInfoSendState.value == 4){
        closeUmiSpeak();
     }
     interactiveId = 0;
     hideInteractive.value = false;
    };

    const exitEvaluationView = () => {
      showEvaluationView.value = false;
      evaluationList.value = [];
      closeUmiSpeak();
    };

    const chatScaleMethod = () => {
      getScaleListRequest(scaleIds);
    };

    const voicePlayText = (text: string, start: boolean, finish: boolean) => {
      speakEndTimeStamp = getCurrentTime();
      if (speakInfoSendState.value == 4) {
        //如果在说话中，才调用打断
        closeUmiSpeak();
      }
      
      const requestId = uuid.v4();
      if (start) {
        setTimeout(() => {
          evlAudioState = 1;
          emit("sendTextToMouthCallBackMethod", requestId, text);
        }, 50);
      } else if (finish) {
        //最后一题，在讲话中快速提交，会先打断，导致调用错乱，因此使用setTimeout
        setTimeout(() => {
          evlAudioState = 3;
          emit("sendTextToMouthCallBackMethod", requestId, text);
        }, 50);
      } else {
        emit("sendTextToMouthCallBackMethod", requestId, text);
      }
    };

    const setWelcomMessage = () => {
      if (
        !props.initUnityCallBack ||
        !webrtcConnect.value ||
        !props.hasInitService ||
        !props.sdkToken
      )
        return;
      //遍历用户历史记录
      // let hasHistory = true;
      const keys = Object.keys(localStorage);
      const filteredArray = keys.filter((element) =>
        element.includes(`${tempLinkardId}_chatMessage`)
      );
      if (filteredArray.length > 0) {
        var localMessage = localStorage.getItem(filteredArray[0]);
        if (localMessage) {
          list.value = JSON.parse(localMessage);
          localMessage = null;
          handleScroll(true);
          return;
        } else {
          // hasHistory = false;
        }
      } else {
        // hasHistory = false;
      }

      if (
        props.greetingMsg &&
        props.greetingMsg.playAudio &&
        props.greetingMsg.text
      ) {
        showInteractiveGuide.value = true;
      } else {
        showGreetingMsgMedia();
      }
    };

    const showGreetingMsgMedia = () => {
      if (
        !props.greetingMsg ||
        (!props.greetingMsg.text &&
          (!props.greetingMsg.image || !props.greetingMsg.image.url) &&
          (!props.greetingMsg.video || !props.greetingMsg.video.url))
      ) {
        //如果没有配置任何欢迎语，则直接发送默认欢迎语
        toAnswer("嗨，向我介绍下你自己吧!");
        return;
      }
      if (
        props.greetingMsg &&
        !props.greetingMsg.playAudio &&
        props.greetingMsg.text
      ) {
        handleAudioKeyWorld(props.greetingMsg.text);
        storageChatMessage();
      }
      if (props.greetingMsg.image && props.greetingMsg.image.thumbnail) {
        const botAnswer: BotAnswer = {
          fromBot: true,
          fromAudio: false,
          triggerType: 4,
          url: props.greetingMsg.image.url,
          mediaUrl: props.greetingMsg.image.thumbnail,
          text: ``,
        };
        setTimeout(() => {
          list.value.push(botAnswer);
        }, 50);
        linkardNet.buryingPointChatDataReport(
          tempLinkardId,
          "robot",
          "word",
          props.greetingMsg.image.thumbnail,
          route.fullPath,
          buryingPointData.GetBuryingPointDataInfo()
        );
      }

      if (props.greetingMsg.video && props.greetingMsg.video.thumbnail) {
        const botAnswer: BotAnswer = {
          fromBot: true,
          fromAudio: false,
          triggerType: 5,
          url: props.greetingMsg.video.url,
          mediaUrl: props.greetingMsg.video.thumbnail,
          text: ``,
        };
        setTimeout(() => {
          list.value.push(botAnswer);
        }, 50);
        linkardNet.buryingPointChatDataReport(
          tempLinkardId,
          "robot",
          "word",
          props.greetingMsg.video.thumbnail,
          route.fullPath,
          buryingPointData.GetBuryingPointDataInfo()
        );
      }
      storageChatMessage();
    };

    const setReportId = (id: string) => {
      curScaleReportId = id;
      evaluationListRequest();
    };

    const toMyEvaluationClick = () => {
      emit("showMyEvaluationListMethod");
    };

    const evaluationListRequest = async () => {
      linkardNet.getScaleReportList(tempLinkardId, 1, 10).then(
        (response) => {
          if (response && response.list.length > 0) {
            hasEvaluationList.value = true;
          } else {
            hasEvaluationList.value = false;
          }
        },
        (ex) => { }
      );
    };

    const closeInteractiveGuide = () => {
      const currentTime = getCurrentTime();
      voiceDuration = currentTime;
      audioRequstId = tempLinkardId + currentTime.toString();
      greetMsgAudioId = audioRequstId;
      emit(
        "sendTextToMouthCallBackMethod",
        audioRequstId,
        props.greetingMsg.text
      );
      hasSendGreetMsgAudio = true;
      showInteractiveGuide.value = false;
    };

    //切换场景
    const setRegisterSetChatParam = (npcId: string, isReset: boolean = false) => {
      hideInteractiveViewClick();
      if (isReset) {
        emit(
          "resetRegisterSetChatParam",
          props.linkardId
        );
        tempLinkardId = props.linkardId;
      } else {
        emit(
          "resetRegisterSetChatParam",
          npcId
        );
        tempLinkardId = npcId;
      }
    };

    onMounted(() => {
      if (props.showChat) {
        buryingPointData.chatId = uuid.v4();

        if (tempLinkardId) {
          evaluationListRequest();
        }
        micPermissionStatus.value = props.micPermissions;
      }
    });

    return {
      message,
      answering,
      loading,
      list,
      currentBotAnswer,
      speakButtonSelect,
      speakInfoSendState, // 0:可点击 1:点中发送中 2:发送loading中 3:思考中 4:说话中
      showUmiAudioView, // 是否显示语音动画、时间、思考中提示
      showVideoUrl,
      botAnswerQueue,
      splitMessageTimer,
      speakSpressDownTimer,
      scrollHeight,
      audioRequstId,
      voiceDuration,
      speakEndTimeStamp,
      recordLineBreakChar,
      richTextReg,
      textTriggerList,
      imgTriggerList,
      videoTriggerList,
      linkTriggerList,
      tempBuffer,
      //触发词相关 1:文本 2:图片 3:视频 4:链接
      triggerType,
      showVideoPlayTriggerView,
      validUrlList, //当前会话的合法url list
      isTriggerText,
      speakCenterBtnRef,
      interactiveViewRef,
      speakPointInside,
      currentUserAsk,
      iscallingUmi,
      webrtcConnect,
      chatAnchors,
      anchorHeight,
      showEvaluationView,
      scaleIdIdentify,
      evaluationList,
      evaluationViewRef,
      hasEvaluationList,
      showInteractiveGuide,
      micPermissionStatus,
      reviewStep,
      interactiveId,
      hideInteractive,
      closeInteractiveGuide,
      toMyEvaluationClick,
      exitEvaluationView,
      showEvaluationViewClick,
      showInteractiveViewClick,
      hideInteractiveViewClick,
      vanFloatingPanelClick,
      showVideoPaly,
      dismissVideoTriggerPlayView,
      stopCountDownMethod,
      closeUmiSpeak,
      inputFocus,
      handleKeyboard,
      toAnswer,
      speakCenterBttonClick,
      handleSpeakCenterButtonEndClick,
      speakButtonClick,
      receiveChatResponse,
      audioPlayResponse,
      updateChatText,
      goBack,
      updatePosition,
      showChatMessageListMethod,
      updateSTTTextMethod,
      callUmiClick,
      webrtcConnectionStateMethod,
      chatScaleMethod,
      voicePlayText,
      setReportId,
      setRegisterSetChatParam,
      callUmiIcon_normal: require("../assets/image/umi_call_normall.svg"),
      callUmiIcon_going: require("../assets/image/umi_call_going.svg"),
    };
  },
});
</script>

<style lang="less" scoped>
.chat-container {
  position: relative;
  padding: 16px 8px;
  padding-bottom: 0;
  overflow: hidden;
  height: 100%;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  pointer-events: none;

  .top_left_action {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    top: 50px;
    left: 0;
    position: absolute;

    :deep(.van-icon-arrow-left) {
      font-size: 48px;
      color: #fff;
      pointer-events: auto;
      z-index: 20;
      margin-left: 26px;
    }

    .my_evaluation_bg {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      font-size: 12px;
      color: #f0f0f0;
      background: #242424;
      width: 104px;
      height: 48px;
      border: 1px solid #f0f0f0;
      border-left: none;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      pointer-events: auto;
      margin-top: -10px;

      .van-image {
        width: 17px;
        height: 20px;
        margin: 0 18px 0 16px;
      }
    }

    .my_evaluation_bg:active {
      opacity: 0.6;
    }
  }
  .SceneSwitchButton{
    display: flex;
  }

  .interactive_buttons{
    position: absolute;
    right: 10px;
    z-index: 1000;
    top: calc(28% - 20px);
    flex-direction: column;
    display: flex;
    gap: 10px;

    .evaluation_edit_button {
      width: 64px;
      height: 64px;
    
      border-width: 0;
      pointer-events: auto;
      border-radius: 12px;
      background-color: #f0f0f0;

      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等

      .evaluation_edit_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .evaluation_edit_icon {
          width: 28px;
          height: 28px;
        }

        .evaluation_edit_title {
          color: #242424;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: 14px;
          height: 14px;
          margin-top: 4px;
        }
      }
    }
  }

  .evaluation_edit_button:active {
    opacity: 0.6;
  }

  .intelligent_agent_language_tips {
    width: 134px;
    height: 20px;
    line-height: 20px;
    border-radius: 40px;
    background: rgba(36, 36, 36, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    text-align: center;
    position: absolute;
    top: 56px;
    font-size: 10px;
    color: #ffffff;
    font-weight: 500;
    align-self: center;

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等
  }

  .chat_message_list_and_call_bg {
    position: absolute;
    top: 46px;
    right: 16px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    .chat_message_list_btn {
      width: 80px;
      height: 36px;
      border-radius: 32px;
      // background: #F0F0F0;
      border: none;
      padding: 0;
      pointer-events: auto;

      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等

      .list_img {
        width: 20px;
        height: 20px;

        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等
      }

      .list_tips {
        color: #242424;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        margin-left: 2px;

        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等
      }
    }

    .continue_call_btn {
      width: 40px;
      height: 40px;
      pointer-events: auto;
      border: none;
      background-color: transparent;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .continue_call_btn:hover {
      opacity: 0.8;
    }

    .continue_call_btn:active {
      opacity: 0.6;
    }
  }

  // .chat_message_list_btn:active {
  //   opacity: 0.6;
  // }

  .van-floating-panel_header_view {
    height: 22px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;

    .van-floating-panel__header-bar {
      width: 36px;
      height: 4px;
      border-radius: 2px;
      background-color: #d9d9d9;
    }
  }

  :deep(.van-safe-area-bottom) {
    padding-bottom: 80px !important;
  }

  :deep(.van-floating-panel) {
    background-color: transparent !important;
    pointer-events: auto;

    .van-floating-panel__content {
      background-color: transparent !important;
    }

    .van-floating-panel__content::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .van-floating-panel__content::-webkit-scrollbar {
      width: 0px;
    }

    .van-floating-panel__content::-webkit-scrollbar-thumb {
      width: 0px;
    }
  }

  .gradient-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,
        rgba(36, 36, 36, 0.3) 15.68%,
        rgba(36, 36, 36, 0) 30%);
    z-index: 1;
    border-radius: 32px;
    pointer-events: none;
  }

  .content {
    border-radius: 32px;
    padding: 0 0 16px 0;
    height: 180px;
    box-sizing: border-box;
    margin-bottom: 100px;
    position: relative;
    z-index: 10;
    pointer-events: auto;

    .content-wrapper {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      /* ios5+ */
      height: 150px;
    }

    .top-umi-content {
      position: absolute;
      bottom: 20px;
      left: 36px;

      .talk-text {
        width: 163px;
        height: 36px;
        line-height: 36px;
        background: linear-gradient(180deg,
            #f2f2f2 0%,
            rgba(36, 36, 36, 0) 100%);
        border-radius: 36px;
      }
    }

    .nav-bar-content {
      height: 188px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // align-items: center;
      justify-content: space-between;
      background-repeat: no-repeat;
      font-size: 16px;
      color: #242424;
      font-weight: 500;
      background-size: cover;
      border-radius: 24px;
      background: linear-gradient(180deg,
          rgba(36, 36, 36, 0) 50%,
          #242424 100%);

      .van-image {
        height: 44px;
        margin-top: auto;
        margin-left: 16px;
        opacity: 0.6;
      }

      .linkard {
        font-size: 12px;
      }
    }

    .chat-list-content {
      padding: 0 10px;
      padding-bottom: 300px;
      height: 100%;
      overflow-y: auto;

      :deep(.van-cell) {
        background-color: transparent;
        padding: 0;
      }

      :deep(.van-cell:after) {
        border-bottom: 0;
      }
    }

    .chat_list_content_pc::-webkit-scrollbar-track {
      background-color: transparent;
      display: none;
    }

    .chat_list_content_pc::-webkit-scrollbar {
      width: 0px;
      display: none;
    }

    .chat_list_content_pc::-webkit-scrollbar-thumb {
      width: 0px;
      background-color: transparent;
      border-radius: 0px;
      display: none;
    }

    .bar-con {
      width: calc(100% - 32px);
      bottom: 12px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-top: 18px;

      .umi-head {
        width: 40px;
        height: 40px;
      }

      .talk-input {
        width: 143px;
        height: 36px;
        line-height: 36px;
        background: #f2f2f2;
        border-radius: 20px;
        font-size: 12px;
        color: #000;
        font-weight: 500;
        margin-left: 20px;
      }
    }

    .init-topic-list {
      .van-divider {
        color: #242424;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .van-divider::after,
      .van-divider::before {
        border-color: #242424;
      }

      .topic-list-item {
        padding: 0 10px;

        .talk-input {
          height: 48px;
          line-height: 48px;
          border-radius: 20px;
          background: #f2f2f2;
          color: #242424;
          font-size: 12px;
          font-weight: 500;
          padding-left: 20px;
          text-align: left;
          margin-bottom: 26px;
        }
      }
    }
  }

  .content_height {
    height: 300px;
  }

  :deep(.van-nav-bar) {
    .van-nav-bar__arrow {
      font-size: 48px;
      color: #242424;
    }

    .van-nav-bar__left {
      padding-left: 0;
    }
  }

  .send-content {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 26px;
    border-radius: 28px 28px 0px 0px;
    background-color: #f6f6f6;
    backdrop-filter: blur(4px);
    z-index: 1001;
    pointer-events: auto;
    border: 3px solid #242424;
    border-bottom-width: 0;
    min-height: 40px;

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等

    .umi_audio_info {
      // left: 0;
      // right: 0;
      // margin: auto;
      // position: absolute;
      // top: -80px;
    }

    :deep(.van-cell) {
      background: transparent;
      flex-grow: 1;
      margin-left: 20px;
      margin-right: 8px;
      padding: 10px 0;
      width: auto;

      .van-field__control {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        max-height: 48px !important; // 设置最大高度 超出隐藏
      }
    }

    .speak-mic-btn {
      width: 72px;
      height: 36px;
      border-width: 0;
      border-radius: 20px;
      padding: 0;
      margin-right: 10px;
      border: 2px solid #242424;

      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等

      :deep(.van-button__text) {
        width: 100%;
      }

      .speak-btn-mic {
        width: 32px;
        height: 32px;
      }
    }

    .mic-btn {
      background: transparent;
    }

    .speak-btn {
      background: transparent;
    }

    .talk-btn {
      width: 72px;
      height: 36px;
      border: 2px solid #242424;
      padding: 0;
      background: #f3ec3f;
      border-radius: 20px;
      font-size: 12px;
      color: #242424;
      font-weight: 600;
      margin-right: 10px;
    }

    .van-cell-group {
      flex: 1;
      height: 48px;
      line-height: 48px;
      border-radius: 30px;
      background-color: transparent;

      .van-cell {
        line-height: 30px;
        background-color: transparent;
      }
    }

    .speak_center_btn_bg {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      height: 40px;

      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等

      .speak_move_tips {
        width: 208px;
        height: 20px;
        border-radius: 10px;
        background: rgba(36, 36, 36, 0.9);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);

        color: #f0f0f0;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        position: absolute;
        bottom: 86px;
        left: 80px;
      }

      .speak_center_btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 56px;
        border-radius: 16px;
        position: absolute;
        bottom: 18px;
        left: 0px;

        background-color: transparent;
        border: none;

        span {
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等
          pointer-events: none; // 阻止点击、状态变化和鼠标指针变化：
        }

        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-touch-callout: none; // 禁用系统默认菜单、img元素比如保存图像等等

        .hold_down_speak_tips {
          color: #242424;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 36px;
          background-color: #fff;
          height: 36px;
          border-radius: 10px;
          width: calc(100% - 90px * 2);

        }

        .hold_down_speak_tips_disable {
          color: #c0c4cc;
        }
      }

      // .speak_center_btn:active {
      //   background-color: transparent;
      // }

      .speak_center_listen {
        // background: #f3ec3f;
      }

      .speak_center_listen_disable {
        // background: #ccc;
      }
    }
  }
}

.slide-item {
  animation-name: slidedown;
  animation-fill-mode: forwards;
  animation-duration: 1000ms;
  opacity: 0;
}

@keyframes slidedown {
  0% {
    transform: translateY(-80%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
</style>
