import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-143dade8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "book-main-container" }
const _hoisted_2 = { class: "book-container" }
const _hoisted_3 = { class: "close-button" }
const _hoisted_4 = { class: "scene-grid" }
const _hoisted_5 = ["data-id", "onClick"]
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "scene-text" }
const _hoisted_8 = { class: "reader-container" }
const _hoisted_9 = { class: "page-title" }
const _hoisted_10 = { id: "page-title" }
const _hoisted_11 = { class: "book-image-container" }
const _hoisted_12 = ["src", "alt"]
const _hoisted_13 = { class: "text-container" }
const _hoisted_14 = { class: "navigation-buttons" }
const _hoisted_15 = { class: "page-number" }
const _hoisted_16 = { id: "page-number" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = ["disabled"]
const _hoisted_19 = {
  id: "myModal",
  class: "modal"
}
const _hoisted_20 = { class: "modal-content" }
const _hoisted_21 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", {
          id: "close-page",
          class: "close-icon",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePage && _ctx.closePage(...args)))
        }, "×")
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentStoryItem, (scene) => {
          return (_openBlock(), _createElementBlock("div", {
            key: scene.id,
            class: _normalizeClass(["scene-item", { selected: false }]),
            "data-id": scene.id,
            onClick: ($event: any) => (_ctx.handleSceneClick(scene))
          }, [
            _createElementVNode("img", {
              src: scene.coverUrl,
              alt: `Scene ${scene.id}`
            }, null, 8, _hoisted_6),
            _createElementVNode("div", _hoisted_7, _toDisplayString(scene.name), 1)
          ], 8, _hoisted_5))
        }), 128))
      ], 512), [
        [_vShow, _ctx.isSelectedOpen]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.title), 1)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("img", {
            src: _ctx.currentImageSrc,
            alt: _ctx.currentImageText,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
          }, null, 8, _hoisted_12)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("p", null, _toDisplayString(_ctx.currentImageText), 1)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.currentPage) + "/" + _toDisplayString(_ctx.totalPages), 1)
          ]),
          _createElementVNode("button", {
            id: "prev-button",
            class: "nav-button",
            disabled: _ctx.currentPage === 1,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.prevPage && _ctx.prevPage(...args)))
          }, "<", 8, _hoisted_17),
          _createElementVNode("button", {
            id: "next-button",
            class: "nav-button",
            disabled: _ctx.currentPage === _ctx.totalPages,
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args)))
          }, ">", 8, _hoisted_18)
        ])
      ], 512), [
        [_vShow, !_ctx.isSelectedOpen]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_19, [
        _createElementVNode("span", {
          class: "close-modal",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, "×"),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("img", {
            class: "modal-content-img",
            src: _ctx.currentImageSrc
          }, null, 8, _hoisted_21)
        ])
      ], 512), [
        [_vShow, _ctx.isModalOpen]
      ])
    ])
  ]))
}