      <!-- 第三个功能 第二个页面 -->
<template>
    <div class="bookview-main-container">
      <div class="bookview-container">
        <div class="close-button" @click="closePage">
          <span id="close-page" class="close-icon" >&times;</span>
        </div>
        <div class="bookview-image-container">
          <img v-if="itemInfo.type === 'image'" :src="itemInfo.url" />
          <source v-if="itemInfo.type === 'video'" :src="itemInfo.url" type="video/mp4" />
          <span v-if="itemInfo.type === 'text'" class="scene-text" >{{itemInfo.content}}</span>
          <iframe v-if="itemInfo.type === 'link'" :src="itemInfo.url" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent,ref, PropType } from 'vue';
  import { SubMenuItem } from "@/net/linkardNet";
  
  export default defineComponent({
    props: {
      imageUrl: {
        type: String,
        default: '',
      },
      itemInfo:{
        type: Object as PropType<SubMenuItem>,
        default: () => ({}) 
      }
    },
    name: 'bookImageView',
    setup(props, { emit }) {
      const url = ref("https://admin.test.linkard.me/home");
      const type = ref("link");
      const imageSrc = props.imageUrl==''?"https://photo.16pic.com/00/51/73/16pic_5173641_b.jpg": props.imageUrl;
      const closePage = () => {
        emit('closeBookImage');
      };
  
      return {
        url,
        imageSrc,
        closePage
      };
    }
  });
  </script>
  
  <style scoped>
  .bookview-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative; /* 确保子元素的绝对定位相对于此容器 */
  z-index: 1002; 
}

.bookview-container {
  text-align: center;
  flex-shrink: 0;
  background: #F0F0F0;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; /* 确保子元素的绝对定位相对于此容器 */
  z-index: 1002; 
}

.bookview-image-container {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1002; 
}

.bookview-image-container img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}
.scene-text {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
}
  
  /* 关闭按钮样式 */
  .close-button {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    z-index: 1003; 
  }
  
  .close-icon {
    font-size: 20px;
    color: black;
    display: inline-block;
    z-index: 1003; 
  }

  .scene-html{
    width: 100%;
    height: 100%;
  }
  
  </style>