      <!-- 第一个功能第二个页面，右侧点击的button -->
<template>
  <div class="scene-list-main-container">
    <div class="scene-list-container">
      <div class="close-button">
        <span id="close-page" class="close-icon" @click="handleClose">&times;</span>
      </div>
      <div class="scene-list-titles">
        <span class="scene-list-big-title">对话场景切换</span>
        <p/>
        <span class="scene-list-small-title">请在下方选择您想切换的对话场景</span>
      </div>
      <div class="scene-grid">
        <div
          v-for="scene in currentChatSceneItem"
          :key="scene.id"
          class="scene-item"
          :data-id="scene.id"
          @click="handleSceneClick(scene)"
          :class="{ selected: scene.npcId === selectedScene }"
        >
          <img :src="require('../../assets/image/scene_bg.svg')" :alt="`Scene ${scene.id}`" />
          <div class="scene-text">{{ scene.name }}</div>
          <div class="scene-action" v-if="scene.npcId === selectedScene">正在使用此场景</div>
        </div>
      </div>
      <button
        id="reset-button"
        class="reset-button"
        :disabled="resetButtonDisabled"
        @click="handleResetClick"
      >
        {{ resetButtonText }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { any } from 'video.js/dist/types/utils/events';
import { ChatSceneItem,linkardNet } from "@/net/linkardNet";
import { HttpResponse } from "@/net/http";
import { defineComponent, ref, onMounted, onBeforeUnmount,Ref,onUpdated } from 'vue';
import { showToast } from 'vant';


export default defineComponent({
  props: {
    linkardId: {
      type: String,
      default: '',
    },
    sceneId: {
      type: String,
      default: '',
    },
  },
  name: 'CoordinateList',
  setup(props, { emit }) {
    // const scenes = ref([
    //   { id: "1", image: 'https://photo.16pic.com/00/25/31/16pic_2531248_b.jpg', name: '场景1',npcId:"" },
    //   { id: "2", image: 'https://photo.16pic.com/00/25/31/16pic_2531248_b.jpg', name: '场景2',npcId:"" },
    //   { id: "3", image: 'https://photo.16pic.com/00/25/31/16pic_2531248_b.jpg', name: '场景3',npcId:"" },
    //   { id: "4", image: 'https://photo.16pic.com/00/25/31/16pic_2531248_b.jpg', name: '场景4',npcId:"" },

    // ]);

    const selectedScene = ref("");
    const resetButtonDisabled = ref(true);
    const resetButtonText = ref('恢复默认对话场景');
    const currentChatSceneItem: Ref<ChatSceneItem[] | undefined> = ref();

    const initSceneParam =(sceneId:string) =>{
      selectedScene.value = sceneId;
    }

    const handleSceneClick = (scene:any) => {
      selectedScene.value = scene.npcId;
      resetButtonDisabled.value = false;  
      showToast("已切换为「"+scene.name+"」对话场景");
      emit('sceneClick',scene.npcId , false);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
    };

    const handleResetClick = () => {
      if (!resetButtonDisabled.value) {
        selectedScene.value = "";
        resetButtonDisabled.value = true;
        showToast("已恢复为默认对话场景");
        emit('sceneClick',"0",true);        
      }
    };

    const handleClose = () => {
      emit('closeSceneList');
    };

    
    const getStudyLinkardQueryRequest = () => {
      linkardNet.getChatSceneList(props.linkardId,1,10).then(
        (response) => {
          currentChatSceneItem.value = response.list;
        },
        (ex) => { 
        }
      );
    };

    const updateQuery = () => {
      getStudyLinkardQueryRequest();
    };

    onMounted(() => {
      initSceneParam(props.sceneId);
    });

    return {
     // scenes,
      selectedScene,
      resetButtonDisabled,
      resetButtonText,
      currentChatSceneItem,
      updateQuery,
      handleSceneClick,
      handleResetClick,
      handleClose
    };
  }
});
</script>

<style scoped>
/* 这里放置CSS样式 */
.scene-list-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1001;
}

.scene-list-container {
  text-align: center;
  flex-shrink: 0;
  background: #F0F0F0;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1001;
}

.scene-list-big-title {
  text-align: center;
  font-size: 15px;
  margin: 0;
  font-weight: bold;
  align-items: center;
}

.scene-list-small-title {
  text-align: center;
  font-size: 12px;
  margin: 0;
  font-weight: normal;
  align-items: center;
}

.scene-list-titles {
  padding: 20px;
  width: 100%;
  height: 70px;
  gap: 5px;
  align-items: center;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scene-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);
  width: 100%;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  max-height: 250px; /* 根据需要调整高度 */
  overflow-y: auto; /* 添加垂直滚动条 */
}

.scene-item {
  position: relative;
  border-radius: 20px;
  background-color: #F0F0F0;
border: 6px solid white;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
}

.scene-item img {
  width: 100%;
  height:  100%;
  display: block;
  object-fit: cover;
}

.scene-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  box-sizing: border-box;
}

.scene-action {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  font-size: 6px;
  text-align: center;
  border-radius: 12px;
  border: 0.5px solid var(--Black, #242424);
  background: var(--Yellow, #F3EC3F);
  padding: 5px 5px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  display: none;
}

.scene-action.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.scene-item.selected {

border: 6px solid var(--Yellow, #F3EC3F);
}

.scene-item.selected .scene-action {
  display: block;
  content: "正在使用此场景";
}

.reset-button {
  padding: 10px 0;
  margin: 40px 0;
  font-size: 10px;
  background: #F3EC3F;
  color: black;
  border: 2px solid black;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s;
  border-radius: 100px;
  text-align: center;
  width: 50%;
}

.reset-button:disabled{
  cursor: not-allowed;
  background: transparent;
}

.reset-button:hover:enabled {
  background: rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 11;
}

.close-icon {
  font-size: 20px;
  color: black;
}
</style>