      <!-- 第一个功能第一个页面，右侧点击的button -->
<template>
  <div id="switchList"  class="switchList-container" v-show="isShowList == 1 ">
    <SceneSwitchList ref="sceneSwitchList" @closeSceneList="hideInteractive" :linkardId = "linkardId" @sceneClick = "sceneClick" :sceneId ="currentSceneId"></SceneSwitchList>
  </div>
  <div id="readerView"  class="switchList-container" v-show="isShowList == 2 ">
    <BookReaderView ref="bookReaderView" @closeBookReader="hideInteractive" :linkardId = "linkardId"  @voicePlayText="voicePlayText"  @closeUmiSpeak ="closeUmiSpeak"></BookReaderView>
  </div>
  <div id="scene-Interactive"  class="scene-Interactive" v-show="isShowList == 0 ">
    <InteractiveMenu  ref="interactiveMenu" @closeSceneList="hideInteractive" :linkardId = "linkardId" :chatMenuList="chatMenuList"></InteractiveMenu>
  </div>

  <!-- <div class="coordinate-main-container"  v-show="!isShowList">
    <div class="coordinate-container">
      <div
        v-for="(coord, index) in coordinates"
        :key="index"
        class="coordinate-block"
        :class="{ 'single-coordinate': coordinates.length === 1 }"
        @click="handleClick(coord, index)"
      >
        <img :src="require(index==0?'../../assets/image/interactive_scene.svg':'../../assets/image/interactive_sequence.svg')" :alt="coord.text" />
        <p>{{ coord.text }}</p>
      </div>
    </div>
  </div> -->
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount,Ref } from 'vue';
import SceneSwitchList from "./sceneSwitchList.vue";
import BookReaderView from "./bookReaderView.vue";
import InteractiveMenu from "./InteractiveMenu.vue";

export default defineComponent({
  props: { 
    linkardIdParam: {
      type: String,
      default: '',
    },
    chatMenuList: {
      type: Array,
      default: '',
    },
    interactiveId:{
      type: Number,
      default: 0,
    }
  },

  components: {
    SceneSwitchList,
    BookReaderView,
    InteractiveMenu
  },
  name: 'CoordinateList',
  setup(props, { emit }) {
    // 初始化坐标数据
    const coordinates = ref([
      { id: 1, image: '../../assets/image/interactive_scene.svg', text: '对话' },
      { id: 2, image: '../../assets/image/interactive_sequence.svg', text: '序列' },
    //  { id: 3, image: 'https://photo.16pic.com/00/25/31/16pic_2531248_b.jpg', text: '互动' }
    ]);
    var isShowList = ref(0);
    var currentSceneId = ref('0');
    var linkardId = ref("");
    var sceneSwitchList  = ref();
    var bookReaderView = ref();
    var interactiveMenu = ref();
    // 处理点击事件
    const handleClick = (coord: { id: number, image: string, text: string }, index: number) => {
      isShowList.value = index+1;

    };

    // 组件挂载时的初始化操作
    onMounted(() => {
      isShowList.value = props.interactiveId;
      linkardId.value = props.linkardIdParam;
    });
                                                                                                                                                                     
    // 组件卸载前的清理操作
    onBeforeUnmount(() => {
      // 清理操作，
    });

    const setSceneList = (interaciveId :number) => {
      isShowList.value = interaciveId;
      if(interaciveId==1){
        sceneSwitchList.value.updateQuery();
      }else if(interaciveId==2){
        bookReaderView.value.updateQuery();
      }
      // 清理操作，
    };

    const setLinkardId = (tmplinkardId :string) => {
      linkardId.value = tmplinkardId;
      // 清理操作，
    };

    const hideInteractive = () => {
      isShowList.value = 0;
      emit('closeInteractive');
    };

    const nextAutoVoicePage = () => {
      if(isShowList.value == 2){
        bookReaderView.value.nextAutoVoicePage();
      }
    };

    const sceneClick =(sceneId:string,reset:Boolean)=>{
      isShowList.value = 0;
      currentSceneId.value = sceneId;
      emit('sceneClick',sceneId,reset);   
    }

    const closeUmiSpeak =()=>{
      emit("closeUmiSpeak");
    };

    const voicePlayText = (
      text: string,
      start: boolean,
      finish: boolean
    ) => {
      emit("voicePlayText", text, start, finish);
    };

    return {
      coordinates,
      isShowList,
      currentSceneId,
      linkardId,
      sceneSwitchList,
      bookReaderView,
      sceneClick,
      handleClick,
      hideInteractive,
      setSceneList,
      setLinkardId,
      voicePlayText,
      nextAutoVoicePage,
      closeUmiSpeak,
      BookReaderView,
      InteractiveMenu,
    };
  }
});
</script>

<style scoped>
.switchList-container{
  width:calc(100% - 56px);
  height:100%;
  padding:28px 28px 28px 28px;
  pointer-events: auto;
  display:flex;
  position:absolute;
  z-index:3001;
}

.scene-Interactive {
    width:100%;
    height:100%;

    display:flex;
    position:relative
  }

.coordinate-main-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 100%;
  height:100%;
  position: absolute;
  z-index:1000;
}

/* 容器样式 */
.coordinate-container {
 /* background: #F0F0F0; */
  border-radius: 10px;
  padding: 2px;
/* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);*/
  display: flex;
  width: 40px;
  right: 0px;
  flex-direction: column;
  top: 40%;
  position: absolute;
  right: 10px;
  z-index: 1000;
}

/* 坐标块样式 */
.coordinate-block {
    width: 64px;
    height: 64px;
    position: absolute;

    top: calc(43% - 20px);
    border-width: 0;
    pointer-events: auto;
    border-radius: 12px;
    background-color: #f0f0f0;

    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none; 
}

.coordinate-block:last-child {
  margin-bottom: 0;
}

/* 单个坐标块为圆角正方形 */
.coordinate-block.single-coordinate {
  aspect-ratio: 1 / 1;
}

/* 图片样式 */
.coordinate-block img {
  max-width: 35px;
  max-height: 35px; /* 可以根据需要调整高度 */
}

/* 文字样式 */
.coordinate-block p {
  margin: 0;
  font-size: 8px;
  color: #333;
}

/* 鼠标悬停效果 */
.coordinate-block:hover {
  background: #E0E0E0;
  cursor: pointer;
}
</style>