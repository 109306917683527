import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e4e6b0a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bookImageView-container" }
const _hoisted_2 = { class: "interactive-menu" }
const _hoisted_3 = {
  class: "scroll-container",
  ref: "scrollContainerRef"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookImageView = _resolveComponent("BookImageView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BookImageView, {
        onCloseBookImage: _ctx.closeBookImage,
        itemInfo: _ctx.selectedOption
      }, null, 8, ["onCloseBookImage", "itemInfo"])
    ], 512), [
      [_vShow, _ctx.isShowImage]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "scroll-content",
          style: _normalizeStyle({ transform: `translateX(${_ctx.scrollLeft}px)` }),
          ref: "scrollContentRef"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chatMenuList, (menuItem, index) => {
            return (_openBlock(), _createElementBlock("button", {
              key: index,
              onClick: ($event: any) => (_ctx.showOptionsMenu(index))
            }, _toDisplayString(menuItem.name), 9, _hoisted_4))
          }), 128))
        ], 4)
      ], 512),
      _createVNode(_Transition, { name: "slide-fade" }, {
        default: _withCtx(() => [
          (_ctx.isOptionsMenuVisible)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "options-menu",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideOptionsMenu && _ctx.hideOptionsMenu(...args)))
              }, [
                _createElementVNode("div", {
                  class: "options-list",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsList, (option, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      onClick: ($event: any) => (_ctx.selectOption(option))
                    }, _toDisplayString(option.name), 9, _hoisted_5))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ], 512), [
      [_vShow, !_ctx.isShowImage]
    ])
  ], 64))
}